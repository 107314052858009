/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store';


Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'loginView',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/',
    name: 'dashboard',
    //component: () => import('../views/userDashboard/index.vue'),
    component: () => import('../views/landingPage/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: () => import('../views/users/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/usuarios/crear',
    name: 'usuarios/crear',
    component: () => import('../views/users/create.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/usuarios/editar/:id',
    name: 'usuarios/editar',
    params: true,
    component: () => import('../views/users/edit.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/roles',
    name: 'roles',
    component: () => import('../views/roles/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/roles/crear',
    name: 'roles/crear',
    component: () => import('../views/roles/create.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/roles/editar/:id',
    name: 'roles/editar',
    params: true,
    component: () => import('../views/roles/edit.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/permisos',
    name: 'permisos',
    component: () => import('../views/permissions/index.vue'),
    meta: { requiresAuth: false } 
  },
  {
    path: '/permisos/crear',
    name: 'permisos/crear',
    component: () => import('../views/permissions/create.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/permisos/editar/:id',
    name: 'permisos/editar',
    params: true,
    component: () => import('../views/permissions/edit.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/listas-seleccionables',
    name: 'listas-seleccionables',
    component: () => import('../views/selectableLists/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/listas-seleccionables/crear',
    name: 'listas-seleccionables/crear',
    component: () => import('../views/selectableLists/create.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/listas-seleccionables/editar/:id',
    name: 'listas-seleccionables/editar',
    params: true,
    component: () => import('../views/selectableLists/edit.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/gastos-generales',
    name: 'gastos-generales',
    component: () => import('../views/generalExpenses/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/gastos-generales/crear',
    name: 'gastos-generales/crear',
    component: () => import('../views/generalExpenses/create.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/gastos-generales/editar/:id',
    name: 'gastos-generales/editar',
    params: true,
    component: () => import('../views/generalExpenses/edit.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/mano-obra',
    name: 'mano-obra',
    component: () => import('../views/positions/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/mano-obra/crear',
    name: 'mano-obra/crear',
    component: () => import('../views/positions/create.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/mano-obra/editar/:id',
    name: 'mano-obra/editar',
    params: true,
    component: () => import('../views/positions/edit.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/equipos-servicios',
    name: 'equipos-servicios',
    component: () => import('../views/eqServ/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/materiales',
    name: 'materiales',
    component: () => import('../views/materials/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/materiales/crear',
    name: 'materiales/crear',
    component: () => import('../views/materials/create.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/materiales/editar/:id',
    name: 'materiales/editar',
    component: () => import('../views/materials/edit.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/equipos-servicios/crear',
    name: 'equipos-servicios/crear',
    component: () => import('../views/eqServ/create.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/equipos-servicios/editar/:id',
    name: 'equipos-servicios/editar',
    params: true,
    component: () => import('../views/eqServ/edit.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/alertas',
    name: 'alertas',
    component: () => import('../views/alerts/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/grafico/:id',
    name: 'grafico',
    component: () => import('../views/graphics/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/empresas',
    name: 'empresas',
    component: () => import('../views/companies/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/empresas/crear',
    name: 'empresas/crear',
    component: () => import('../views/companies/create.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/empresas/editar/:id',
    name: 'empresas/editar',
    params: true,
    component: () => import('../views/companies/edit.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/empresas/ver/:id',
    name: 'empresas/ver',
    params: true,
    component: () => import('../views/companies/view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/empresas/:id/divisiones/ver/:idDivision',
    name: '/empresas/divisiones/ver',
    params: true,
    component: () => import('../views/companies/subdivisions/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/rangos',
    name: 'rangos',
    component: () => import('../views/ranges/edit.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/ofertas',
    name: 'ofertas',
    component: () => import('../views/offers/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/ofertas/crear',
    name: 'ofertas/crear',
    component: () => import('../views/offers/create.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/ofertas/editar/:id',
    name: 'ofertas/editar',
    params: true,
    component: () => import('../views/offers/edit.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/ofertas/ver/:id',
    name: 'ofertas/ver',
    params: true,
    component: () => import('../views/offers/detail.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/ofertas/:id/cotizaciones/crear',
    name: 'cotizaciones/crear',
    params: true,
    component: () => import('../views/offers/quotes/create.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/ofertas/:id/importar',
    name: 'cotizaciones/importar',
    params: true,
    component: () => import('../views/offers/quotes/massive.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/cotizaciones/:id',
    name: 'cotizaciones',
    params: true,
    component: () => import('../views/offers/quotes/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/cotizaciones/editar/:id',
    name: 'cotizaciones/editar',
    params: true,
    component: () => import('../views/offers/quotes/edit.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/cotizaciones/ver/:id',
    name: 'cotizaciones/ver',
    params: true,
    component: () => import('../views/offers/quotes/detail.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/solicitudes',
    name: 'solicitudes',
    component: () => import('../views/applications/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/solicitudes/acreditacion/',
    name: 'solicitudes/acreditacion',
    component: () => import('../views/accapplications/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/solicitudes/crear',
    name: 'solicitudes/crear',
    component: () => import('../views/applications/create.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/solicitudes/editar/:id',
    name: 'solicitudes/editar',
    params: true,
    component: () => import('../views/applications/edit.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/nomina/:id',
    name: 'nomina/',
    params: true,
    component: () => import('../views/payroll/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/nomina/acreditacion/:id',
    name: 'nomina/acreditacion/',
    params: true,
    component: () => import('../views/accpayroll/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/nomina/acreditacion/fichapersonal/:rut',
    name: 'nomina/acreditacion/fichapersonal/',
    params: true,
    component: () => import('../views/personalsheets/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/horas-extra',
    name: 'horas-extra/',
    params: true,
    component: () => import('../views/extraHours/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/horas-extra/crear',
    name: 'horas-extra/crear/',
    params: true,
    component: () => import('../views/extraHours/create.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/horas-extra/ver/:id',
    name: 'horas-extra/ver/',
    params: true,
    component: () => import('../views/extraHours/view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/horas-extra-detalle',
    name: 'horas-extra-detalle/',
    params: true,
    component: () => import('../views/extraHours/detail.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/horas-extra-informe',
    name: 'horas-extra-informe/',
    params: true,
    component: () => import('../views/extraHours/inform.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/horas-extra-informe-semanal',
    name: 'horas-extra-informe-semanal/',
    params: true,
    component: () => import('../views/extraHours/informWeek.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/horas-extra-firma',
    name: 'horas-extra-firma/',
    params: true,
    component: () => import('../views/signatureApplications/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/horas-extra-informe-area',
    name: 'horas-extra-informe-area/',
    params: true,
    component: () => import('../views/extraHours/informArea.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/horas-extra-buk',
    name: 'horas-extra-buk/',
    params: true,
    component: () => import('../views/extraHoursBuk/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/pusherTest',
    name: 'pusherTest/',
    params: true,
    component: () => import('../views/extraHours/pusher.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/nomina/acreditacion/fichapersonal/documento/:url',
    name: 'nomina/acreditacion/fichapersonal/documento/',
    params: true,
    component: () => import('../views/personalsheets/document.vue')
  },
  {
    path: '/userDashboard',
    name: 'userDashboard',
    component: () => import('../views/userDashboard/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/zonas',
    name: 'zonas',
    component: () => import('../views/zones/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/zonas/crear',
    name: 'zonas/crear',
    component: () => import('../views/zones/create.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/zonas/editar/:id',
    name: 'zonas/editar',
    params: true,
    component: () => import('../views/zones/edit.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/empresas/divisiones/porcentajes/ver/:id',
    name: 'porcentajes/ver',
    params: true,
    component: () => import('../views/companies/subdivisions/percentages/edit.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/tarjetas-bloqueo',
    name: 'tarjetas-bloqueo/',
    params: true,
    component: () => import('../views/credential/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/proyectos',
    name: 'proyectos/',
    params: true,
    component: () => import('../views/projects/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/proyectos/crear',
    name: 'proyectos/crear',
    params: true,
    component: () => import('../views/projects/create.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/proyectos/editar/:id',
    name: 'proyectos/editar',
    params: true,
    component: () => import('../views/projects/edit.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/proyectos/gantt',
    name: 'proyectos/gantt',
    params: true,
    component: () => import('../views/projects/gantt.vue'),
    meta: { requiresAuth: true },
    props: route => ({ ganttData: route.params.ganttData }) 
  },
  {
    path: '/proyectos/tareas/:projectId',
    name: 'proyectos/tareas',
    params: true,
    component: () => import('../views/tasks/index.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/proyectos/tareas/detalle/:taskId',
    name: 'proyectos/tareas/detalle',
    params: true,
    component: () => import('../views/tasks/detail.vue'),
    meta: { requiresAuth: true } 
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   // Verifica si la ruta requiere autenticación y el usuario no está autenticado
//   if (to.meta.requiresAuth && !store.state.auth) {
//     // Redirige al usuario a la página de inicio de sesión
//     next('/login');
//   } else {
//     // Permite el acceso a la ruta
//     next();
//   }
// });
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('auth_token'); // Obtén el token de localStorage

  // Verifica si la ruta requiere autenticación y si no hay token
  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    // Redirige al usuario a la página de inicio de sesión si no está autenticado
    next('/login');
  } else {
    // Permite el acceso a la ruta
    next();
  }
});


export default router
