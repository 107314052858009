import instance from "@/axios-config";
import axios from "axios";

// Función para obtener un recurso existente (GET)
export function readResource(url) {
    return instance.get(`${process.env.VUE_APP_API_PREFIX}${url}`)
    .then(response => {
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(`Error: ${response.status}`);
        }
    })
    .catch(error => {
        throw handleError(error); // Llama a handleError para manejar el error
    });
}

export function getTemplate(url) {
    return instance.get(`${process.env.VUE_APP_API_PREFIX}${url}`, { responseType: 'blob' })
    .then(response => {
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(`Error: ${response.status}`);
        }
    })
    .catch(error => {
        throw handleError(error);
    });
}

// Función para crear un nuevo recurso (POST)
export async function createResource(url, data) {
    try {
        await instance.get('/sanctum/csrf-cookie');

        const response = await instance.post(`${process.env.VUE_APP_API_PREFIX}${url}`, data);
        if (response.status === 200 || response.status === 201) { // 201 Created
            return response.data;
        } else {
            throw new Error(`Error: ${response.status}`);
        }
    } catch (error) {
        throw handleError(error); // Llama a handleError para manejar el error
    }
}

export async function createJsonResource(url, data) {
    try {
        await instance.get('/sanctum/csrf-cookie');

        const response = await instance.post(`${process.env.VUE_APP_API_PREFIX}${url}`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (response.status === 200 || response.status === 201) { // 201 Created
            return response.data;
        } else {
            throw new Error(`Error: ${response.status}`);
        }
    } catch (error) {
        throw handleError(error); // Llama a handleError para manejar el error
    }
}

// export function createResource(url, data) {
//     return instance.post(`${process.env.VUE_APP_API_PREFIX}${url}`, data)
//     .then(response => {
//     if (response.status === 200 || response.status === 201) { // 201 Created
//         return response.data;
//     } else {
//         throw new Error(`Error: ${response.status}`);
//     }
//     })
//     .catch(error => {
//         throw handleError(error); // Llama a handleError para manejar el error
//     });
// }

// Función para actualizar un recurso existente (PUT)
export async function updateResource(url, data) {
    try {
        await instance.get('/sanctum/csrf-cookie');

        const response = await instance.put(`${process.env.VUE_APP_API_PREFIX}${url}`, data);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(`Error: ${response.status}`);
        }
    } catch (error) {
        throw handleError(error); // Llama a handleError para manejar el error
    }
}
// export function updateResource(url, data) {
//     return instance.put(`${process.env.VUE_APP_API_PREFIX}${url}`, data)
//     .then(response => {
//     if (response.status === 200) {
//         return response.data;
//     } else {
//         throw new Error(`Error: ${response.status}`);
//     }
//     })
//     .catch(error => {
//         throw handleError(error); // Llama a handleError para manejar el error
//     });
// }

// Función para actualizar algunos datos de un recurso existente (PATCH)
export async function patchResource(url, data) {
    try {
        await instance.get('/sanctum/csrf-cookie');

        const response = await instance.patch(`${process.env.VUE_APP_API_PREFIX}${url}`, data);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(`Error: ${response.status}`);
        }
    } catch (error) {
        throw handleError(error); // Llama a handleError para manejar el error
    }
}
// export function patchResource(url, data) {
//     return instance.patch(`${process.env.VUE_APP_API_PREFIX}${url}`, data)
//     .then(response => {
//     if (response.status === 200) {
//         return response.data;
//     } else {
//         throw new Error(`Error: ${response.status}`);
//     }
//     })
//     .catch(error => {
//         throw handleError(error); // Llama a handleError para manejar el error
//     });
// }

// Función para eliminar un recurso existente (DELETE)
export async function deleteResource(url) {
    try {
        await instance.get('/sanctum/csrf-cookie');

        const response = await instance.delete(`${process.env.VUE_APP_API_PREFIX}${url}`);
        if (response.status === 204 || response.status === 200) { // 204 No Content
            return; // No hay datos para devolver
        } else {
            throw new Error(`Error: ${response.status}`);
        }
    } catch (error) {
        throw handleError(error); // Llama a handleError para manejar el error
    }
}
// export function deleteResource(url) {
//     return instance.delete(`${process.env.VUE_APP_API_PREFIX}${url}`)
//     .then(response => {
//     if (response.status === 204) { // 204 No Content
//         return; // No hay datos para devolver
//     } else {
//         throw new Error(`Error: ${response.status}`);
//     }
//     })
//     .catch(error => {
//         throw handleError(error); // Llama a handleError para manejar el error
//     });
// }

// Función para manejar errores de forma genérica
function handleError(error) {
    let errorMessage;

    if (error.response) {
        const status = error.response.status;
        if (status === 400) {
            errorMessage = 'Error 400: La solicitud no es válida.';
        } else if (status === 404) {
            errorMessage = 'Error 404: Recurso no encontrado.';
        } else if (status === 422) {
            errorMessage = error.response.data.message;
        } else if (status === 500) {
            errorMessage = 'Error 500: Error interno del servidor.';
        } else {
            errorMessage = `Error ${status}: Se ha producido un error.`;
        }
    }else if (error.request) {
        errorMessage = 'Error: No se pudo conectar al servidor.';
    } else {
        errorMessage = 'Error: ' + error.message;
    }

    return errorMessage;
}

export async function sendToServlet(data) {
    try {
        const response = await axios.post('https://52.70.103.174:8443/mackservlets/api/processMPP', data, {
            headers: {
                'Content-Type': 'multipart/form-data' // Asegúrate de que estás enviando los archivos correctamente
            }
        });
        
        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            throw new Error(`Error: ${response.status}`);
        }
    } catch (error) {
        console.error(error);
        throw error; // Manejar el error adecuadamente
    }
}

export function viewBukPdf(url, data) {
    return instance.post(`${process.env.VUE_APP_API_PREFIX}${url}`, data, {
        responseType: 'blob' // Configura el tipo de respuesta como blob
    })
    .then(response => {
        if (response.status === 200 || response.status === 201) { // 201 Created
            return response.data;
        } else {
            throw new Error(`Error: ${response.status}`);
        }
    })
    .catch(error => {
        throw handleError(error); // Llama a handleError para manejar el error
    });
}

export function uploadDocument(url, formData) {

    return instance.post(`${process.env.VUE_APP_API_PREFIX}${url}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    .then(response => {
        if (response.status === 201) {
            return response.data;
        } else {
            throw new Error(`Error: ${response.status}`);
        }
    })
    .catch(error => {
        throw handleError(error); // Llama a handleError para manejar el error
    });
}

export function downloadDocument(url, data) {
    return instance.post(`${process.env.VUE_APP_API_PREFIX}${url}`, data)
    .then(response => {
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(`Error: ${response.status}`);
        }
    })
    .catch(error => {
        throw handleError(error); 
    });
}

async function getTotalPages(baseUrl, headers) {
    const response = await axios.get(baseUrl, { headers });
    if (response.status === 200) {
        return response.data.pagination.total_pages;
    } else {
        throw new Error(`Error: ${response.status}`);
    }
}

async function fetchPage(url, headers) {
    const response = await axios.get(url, { headers });
    if (response.status === 200) {
        return response.data;
    } else {
        throw new Error(`Error: ${response.status}`);
    }
}

export async function getBukData(url) {
    const baseUrl = url;
    const headers = {
        'auth_token': 'XRuLyNVkMQeXNZFfyS7Fmuz6',
        'Accept': 'application/json'
    };

    try {
        const totalPages = await getTotalPages(baseUrl, headers);
        const urls = Array.from({ length: totalPages }, (_, i) => `${baseUrl}?page=${i + 1}`);
        
        const responses = await Promise.all(urls.map(url => fetchPage(url, headers)));

        return responses;
    } catch (error) {
        console.error(handleError(error)); 
        throw error;
    }
}